
import ApiService from "@/core/services/ApiService"
import { defineComponent, onMounted, ref } from "vue"
import { Edit } from "@element-plus/icons-vue"
import { DateFormatTypes, dateForm } from "@/core/helpers/dateformat"
import { SelectModel } from "@/core/models/SelectModel"

import { FormInstance } from "element-plus"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { PersonNviYearDto } from "@/core/models/PersonNviYearDto"

export default defineComponent({
  name: "account-virman",
  components: {},
  setup() {
    const isBusinessSelectLoading = ref(false)
    const isBranchSelectLoading = ref(false)

    const businessList = ref<SelectModel[]>([])
    const branchList = ref<SelectModel[]>([])

    const studentData = ref<PersonNviYearDto>({
      isForeign: false,
      _pageNumber: 1,
      _pageSize: 10,
    } as PersonNviYearDto)

    const studentList = ref<PersonNviYearDto[]>([])
    const isStudentDataLoading = ref(false)

    const activeBusinessId = ref<string | undefined>()

    const ruleFormRef = ref<FormInstance>()

    const rules = ref({
      branchId: getRule(RuleTypes.SELECT, "Şube"),
    })

    onMounted(async () => {
      await getBusinessList()
    })

    const getStudentList = async formEl => {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          isStudentDataLoading.value = true
          const searchFilter = {
            ...studentData.value,
          }

          const { data } = await ApiService.post("student/search", searchFilter)
          studentList.value = data._data
          totalCount.value = data._totalData
          studentData.value._pageCount = data._pageCount
          isStudentDataLoading.value = false
        }
      })
    }

    const getBusinessList = async () => {
      isBusinessSelectLoading.value = true
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      isBusinessSelectLoading.value = false
      if (businessList.value.length === 1) {
        activeBusinessId.value = businessList.value[0].id
        OnChangeBusiness(activeBusinessId.value)
      }
    }

    const OnChangeBusiness = async businessId => {
      studentData.value.branchId = ""
      await getBranchList(businessId)
    }

    const getBranchList = async businessId => {
      isBranchSelectLoading.value = true
      const config = {
        params: {
          businessId: businessId,
        },
      }
      const { data } = await ApiService.query("branch", config)
      isBranchSelectLoading.value = false
      branchList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (branchList.value.length === 1) {
        studentData.value.branchId = branchList.value[0].id
        getStudentList(ruleFormRef.value)
      }
    }

    const totalCount = ref<number>(0)

    const handleSizeChange = (val: number) => {
      const data = studentData.value
      data._pageSize = val
      studentData.value = data
      getStudentList(ruleFormRef.value)
    }
    const handleCurrentChange = (val: number) => {
      const data = studentData.value
      data._pageNumber = val
      studentData.value = data
      getStudentList(ruleFormRef.value)
    }

    return {
      dateForm,
      DateFormatTypes,
      branchList,
      businessList,
      activeBusinessId,
      Edit,
      getBranchList,
      isBusinessSelectLoading,
      isBranchSelectLoading,
      ruleFormRef,
      rules,
      studentData,
      getStudentList,
      studentList,
      isStudentDataLoading,
      totalCount,
      handleSizeChange,
      handleCurrentChange,
      OnChangeBusiness,
    }
  },
})
